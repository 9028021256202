import axios from "axios";

import ENV from "../../util/env-config";
import { getSessionCookie } from "../../util/common";

class Service {
  static requestConfig() {
    return {
      headers: {
        Authorization: `Bearer ${getSessionCookie()}`,
      },
    };
  }

  static getWebhooks(dealer_id) {
    return axios.post(`${ENV.apiBaseB}/webhooks/list`, { dealer_id }, this.requestConfig());
  }

  static getTriggers() {
    return axios.post(`${ENV.apiBaseB}/webhooks/list_triggers`, {}, this.requestConfig());
  }

  static createWebhook(requestData, dealer_id) {
    return axios.post(`${ENV.apiBaseB}/webhooks/create`, { ...requestData, dealer_id }, this.requestConfig());
  }

  static updateWebhook(hook, dealer_id) {
    return axios.post(`${ENV.apiBaseB}/webhooks/update`, { ...hook, dealer_id }, this.requestConfig());
  }

  static deleteWebhook(id) {
    return axios.post(`${ENV.apiBaseB}/webhooks/delete`, { id }, this.requestConfig());
  }

  static testWebhook(requestData) {
    return axios.post(`${ENV.apiBaseB}/webhooks/test`, requestData, this.requestConfig());
  }

  static listErrors(requestData) {
    return axios.post(`${ENV.apiBaseB}/webhooks/list_errors`, requestData, this.requestConfig());
  }

  static getDealerChecklists(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/dealers/details`, { ...requestData, embed: "checklist" }, this.requestConfig());
  }

  static getLocationsChecklists(requestData) {
    return axios.post(`${ENV.dealerBaseUrl}/locations/details`, { ...requestData, embed: "checklist" }, this.requestConfig());
  }
}

export default Service;
