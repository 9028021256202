import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Button, Form, Icon, Modal, Message, Search, Checkbox } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import TimePicker from "rc-time-picker";

import Can from "../Can";
import CarsService from "./../Cars/service";
import AddAppointmentIntervention from "./AddAppointmentIntervention";
import { DMS } from "../DMSv3/util";
import { ThumbToggle, UserInput } from "../../components";
import { nextlaneEnabled } from "../../util/common";

import Service from "./service";

import "rc-time-picker/assets/index.css";
import "./AddAppointmentForm.css";

class AddAppointmentForm extends Component {
  state = {
    appointment: {
      ref_nr: "",
      wo_nr: "",
      dealer_location_id: this.props.location.id,
      car: {
        reg_nr: "",
        make: "",
        model: "",
        apk_date_dms: moment().set("hour", 12).format(),
        vin_nr: "",
      },
      interventions: [],
      car_return_time: moment().format("YYYY-MM-DD HH:mm"),
      customer_driver: {
        dms_nr: "",
        firstname: "",
        surname: "",
        street: "",
        postcode: "",
        place: "",
        email_business: "",
        tel_mobile_business: "",
        tel_business_nr: "",
      },
      time_car_app: moment().set("hour", 12).format(),
      due_in_time: moment(),
    },
    hasError: false,
    errorMsg: "",
    isCustomerSearchLoading: false,
    selectedCustomer: "",
    customerSearchTerm: "",
    customerSearchTimeout: null,
    customerSearchResults: [],
    customerCarsSearchResults: [],
    customerCarValue: "",
    isCarSearchLoading: false,
    carSearchTerm: "",
    carSearchTimeout: null,
    carSearchResults: [],
    searchCarsHasError: false,
    isLoadingCustomersByCarReg: false,
    customersFoundByCarReg: [],
    carSearchChangeLoading: false,
    searchCustomersByCarLoading: false,
    selectCustomerLoading: false,
    isAddInterventionVisible: false,
  };

  addIntervention = newIntervention => {
    let appointment = { ...this.state.appointment };
    appointment.interventions.push(newIntervention);

    this.setState({ appointment, isAddInterventionVisible: false });
  };

  removeIntervention = index => {
    let appointment = this.state.appointment;
    appointment.interventions.splice(index, 1);

    this.setState({ appointment });
  };

  getRdw = reg_number => {
    CarsService.getRDW({ reg_number })
      .then(result => {
        let appointment = this.state.appointment;

        appointment.car = {
          reg_nr: appointment.car.reg_nr,
          apk_date_dms: result.data.data.vervaldatum_apk ? moment(result.data.data.vervaldatum_apk) : appointment.car.apk_date_dms,
          make: result.data.data.merk || appointment.car.make,
          model: result.data.data.handelsbenaming || appointment.car.model,
        };

        this.setState({ appointment });
      })
      .catch(error => {
        console.log("Error getting the RDW for provided Reg nr.");
      });
  };

  handleCarSearchChange = (event, data) => {
    clearInterval(this.state.carSearchTimeout);

    const carSearchTerm = [DMS.KEYLOOP_MENUS, DMS.KEYLOOP_JOBS].includes(this.props.location.dms_id) ? this.formatRegistrationNumber(data.value) : data.value;

    this.setState({ carSearchTerm, carSearchTimeout: setTimeout(this.searchCars, 700), hasError: false, errorMsg: "" });
  };

  searchCars = () => {
    if (!this.state.carSearchTerm || this.state.carSearchTerm.length < 4 || this.state.isCarSearchLoading) return;
    let result = null,
      carSearchResults = [];
    this.setState({ carSearchChangeLoading: true }, async () => {
      try {
        if (this.props.keyloopEnabled) {
          result = await Service.searchKeyloopCars({ term: this.state.carSearchTerm, dealer_location_id: this.props.location.id });
          carSearchResults =
            result?.data?.data?.vehicles &&
            result.data.data.vehicles.map((car, index) => {
              return {
                key: index,
                id: index,
                title: `${car.description.make || ""} ${car.description.model || ""} - ${car.identification.licensePlate || ""}`,
                car: {
                  reg_nr: car.identification.licensePlate,
                  vin_nr: car.identification.vin,
                  make: car.description.make,
                  model: car.description.model,
                  dms_nr: car.vehicleId,
                },
              };
            });
        } else if (nextlaneEnabled(this.props.location)) {
          result = await Service.searchNextlaneCars({ term: this.state.carSearchTerm, dealer_location_id: this.props.location.id });
          carSearchResults =
            result?.data?.data?.vehicles &&
            result.data.data.vehicles.map((car, index) => {
              return {
                key: index,
                id: index,
                title: `${car.description.make || ""} ${car.description.model || ""} - ${car.identification.licensePlate || ""}`,
                car: {
                  reg_nr: car.identification.licensePlate,
                  vin_nr: car.identification.vin,
                  make: car.description.make,
                  model: car.description.model,
                  dms_nr: car.vehicleId,
                },
              };
            });
        } else {
          result = await Service.getCarByRegNum(this.state.carSearchTerm, this.props.location.id);
          carSearchResults =
            result?.data?.data?.cars &&
            result.data.data.cars.map((car, index) => {
              return {
                key: index,
                id: index,
                title: `${car.make || ""} ${car.model || ""} - ${car.reg_nr || ""}`,
                car: {
                  reg_nr: car.reg_nr,
                  vin_nr: car.vin_nr,
                  make: car.make,
                  model: car.model,
                  dms_nr: car.dms_nr,
                  apk_date_dms: car.apk_date_dms,
                },
              };
            });
        }

        this.setState({ carSearchResults, carSearchChangeLoading: false });
      } catch (error) {
        console.log("Error getting cars for provided search term");
        this.setState({
          carSearchChangeLoading: false,
          hasError: true,
          errorMsg: error?.response?.data?.errors ? error.response.data.errors[0] : "Error getting cars for provided search term",
        });
      }
    });
  };

  handleCarResultSelect = (event, data) => {
    let appointment = this.state.appointment;
    let car = data.result.car;
    let hasError = false;
    let errorMsg = "";

    if (this.props.keyloopEnabled && (!car.reg_nr || !car.make || !car.model || !car.vin_nr)) {
      hasError = true;
      errorMsg = this.props.t("missing_values_in_dms").message || "Missing values must be added in the DMS!";
    }

    appointment.car = car;
    this.setState({ appointment, hasError, errorMsg });
  };

  validateForm = () => {
    let required = [
      { fields: ["time_car_app", "car_return_time", "due_in_time"] },
      { type: "car", fields: ["make", "model"] },
      { type: "customer_driver", fields: ["surname"] },
    ];

    let isValid = true;
    if (!this.props.location["first_name_optional"]) {
      required[2].fields.push("firstname");
    }

    if (this.props.keyloopEnabled) {
      required[1].fields.push("dms_nr");
      required[2].fields.push("dms_nr");
    } else {
      required[0].fields.push("wo_nr");
      required[1].fields.push("apk_date_dms");
    }

    for (let SECTION of required) {
      for (let FIELD of SECTION.fields) {
        if (!SECTION.type) {
          if (!this.state.appointment[FIELD]) {
            isValid = false;
            break;
          }
        } else {
          if (!this.state.appointment[SECTION.type][FIELD]) {
            isValid = false;
            break;
          }
        }
      }

      if (!isValid) break;
    }

    return isValid;
  };

  formatRegistrationNumber = reg => {
    if (reg?.length < 2) return reg;

    let newReg = reg[0];
    for (let i = 1; i < reg.length; i++) {
      if (reg[i - 1] !== "-" && reg[i] !== "-" && /\d/.test(reg[i - 1]) !== /\d/.test(reg[i])) newReg += "-";
      newReg += reg[i];
    }

    return newReg;
  };

  handleChange = (event, data, section, options) => {
    if (this.props.keyloopEnabled && (section === "car" || section === "customer_driver")) return;

    let appointment = this.state.appointment;
    if (section) {
      if (section === "apk_date") {
        appointment.car.apk_date_dms = moment(event).set("hour", 20).format();
      } else if (section === "time_car_app") {
        const time_car_app = moment(event);
        appointment.time_car_app = time_car_app.set("hour", 12).set("minute", 0).set("second", 0).format();
        if (appointment.time_car_app > appointment.car_return_time)
          appointment.car_return_time = time_car_app.format("YYYY-MM-DD") + " " + moment(appointment.car_return_time).format("HH:mm");
      } else if (section === "car_return_time") {
        if (moment(appointment.time_car_app).format("YYYY-MM-DD HH:mm") > moment(event).format("YYYY-MM-DD HH:mm")) return;
        appointment.car_return_time = moment(event).format("YYYY-MM-DD HH:mm");
      } else if (section === "appointment_intervention") {
        appointment.interventions[options.index][data.name] = data.value;
      } else if (section === "intervention_toggle") {
        appointment.interventions[options.index].customer_ok = !appointment.interventions[options.index].customer_ok;
      } else {
        appointment[section][data.name] = data.value;
      }
    } else {
      appointment[data.name] = data.value;
    }

    this.setState({ appointment });
  };

  handleChangeCarInShopCheckbox = (evt, data) => {
    const appointment = { ...this.state.appointment };

    if (data.checked) {
      appointment.car_in_shop = moment().format("YYYY-MM-DDTHH:mm:ssZ");
    } else appointment.car_in_shop = null;

    this.setState({ appointment });
  };

  handleCustomerSearchChange = (event, data) => {
    clearInterval(this.state.customerSearchTimeout);
    this.setState({ customerSearchTerm: data.value, customerSearchTimeout: setTimeout(this.searchCustomers, 700) });
  };

  handleChangeDueInTime = value => {
    let appointment = this.state.appointment;
    appointment.due_in_time = value;
    this.setState({ appointment });
  };

  searchCustomers = () => {
    if (!this.state.customerSearchTerm || this.state.customerSearchTerm.length < 4 || this.state.isCustomerSearchLoading) return;

    this.setState({ isCustomerSearchLoading: true }, () => {
      Service.searchCustomers({
        dealer_id: parseInt(this.props.dealerId, 0),
        term: this.state.customerSearchTerm,
        limit: 15,
        page: 0,
      })
        .then(result => {
          let visibleResults = [];
          if (result.data && result.data.data) {
            result.data.data.forEach((customer_driver, key) => {
              visibleResults.push({
                key: key,
                id: customer_driver.id,
                title: `${customer_driver.title || ""} ${customer_driver.firstname || ""} ${customer_driver.surname || ""} - ${customer_driver.place || ""}`,
                customer_driver,
              });
            });
          }

          this.setState({ isCustomerSearchLoading: false, customerSearchResults: visibleResults, customerCarsSearchResults: [], customerCarValue: "" });
        })
        .catch(error => {
          console.log("Error searching customers.", error);
          this.setState({ isCustomerSearchLoading: false });
        });
    });
  };

  handleCustomerResultSelect = (event, data) => {
    let appointment = this.state.appointment;
    let customer_driver = data.result.customer_driver;

    appointment.customer_driver = {
      id: customer_driver.id,
      firstname: customer_driver.firstname || "",
      surname: customer_driver.surname || "",
      street: customer_driver.street || "",
      postcode: customer_driver.postcode || "",
      place: customer_driver.place || "",
      email_business: customer_driver.email_business || "",
      tel_mobile_business: customer_driver.tel_mobile_business || "",
      tel_business_nr: customer_driver.tel_business_nr || "",
      dms_nr: customer_driver.dms_nr || "",
    };

    this.setState({ appointment, customerCarsSearchResults: [], customerCarValue: "" });
  };

  handleSelectCustomer = (event, data) => {
    const appointment = { ...this.state.appointment };
    if (this.props.keyloopEnabled) {
      let customerID = data.result.customer_driver.customerId;
      this.setState({ selectCustomerLoading: true }, () => {
        Service.getKeyloopCustomer({ customer_id: customerID, dealer_location_id: this.props.location.id })
          .then(result => {
            if (!result?.data?.data) throw Error(this.props("error_retrieving_keyloop_customer").message || "Error retrieving Keyloop customer");

            const keyloopCustomer = result.data.data;
            const customer_driver = {
              firstname: keyloopCustomer.individual?.givenName,
              surname: keyloopCustomer.individual?.familyName,
              street:
                keyloopCustomer.addresses?.physical?.houseNumber +
                " " +
                keyloopCustomer.addresses?.physical?.streetType +
                " " +
                keyloopCustomer.addresses?.physical?.streetName,
              postcode: keyloopCustomer.addresses?.physical?.postalCode,
              place: keyloopCustomer.addresses?.physical?.city,
              email_business: keyloopCustomer.communications?.work.email || keyloopCustomer.communications?.personal?.email,
              tel_business_nr: keyloopCustomer.communications?.work.landline || keyloopCustomer.communications?.personal?.landline,
              tel_mobile_business: keyloopCustomer.communications?.work.mobile || keyloopCustomer.communications?.personal?.mobile,
              dms_nr: keyloopCustomer.customerId,
            };

            appointment.customer_driver = customer_driver;

            const selectedCustomer = `${customer_driver.firstname} ${customer_driver.surname}`;

            this.setState({ appointment, selectedCustomer, selectCustomerLoading: false });
          })
          .catch(error => {
            console.log("Error retrieving customer: ", error);
            this.setState({
              selectCustomerLoading: false,
              hasError: true,
              errorMsg: error.message
                ? error.message
                : error.response.data && error.response.data.errors
                ? error.response.data.errors[0]
                : this.props("error_retrieving_customer").message || "Error retrieving customer",
            });
          });
      });
    } else if (nextlaneEnabled(this.props.location)) {
      let customerDmsNr = data.result.customer_driver.dms_nr;
      this.setState({ selectCustomerLoading: true }, () => {
        Service.getNextlaneCustomer({ dms_nr: customerDmsNr, dealer_location_id: this.props.location.id })
          .then(result => {
            if (result?.data?.data) {
              let nextlaneCustomer = result.data.data;
              let customer_driver = {
                firstname: nextlaneCustomer.names.givenName,
                surname: nextlaneCustomer.names.familyName,
                street:
                  nextlaneCustomer.addresses.home.physicalAddress.houseNumber +
                  " " +
                  nextlaneCustomer.addresses.home.physicalAddress.streetType +
                  " " +
                  nextlaneCustomer.addresses.home.physicalAddress.streetName,
                postcode: nextlaneCustomer.addresses.home.physicalAddress.postalCode,
                place: nextlaneCustomer.addresses.home.physicalAddress.city,
                email_business: nextlaneCustomer.communication.work.email || nextlaneCustomer.communication.home.email,
                tel_business_nr: nextlaneCustomer.communication.work.landline || nextlaneCustomer.communication.home.landline,
                tel_mobile_business: nextlaneCustomer.communication.work.mobile || nextlaneCustomer.communication.home.mobile,
                dms_nr: nextlaneCustomer.customerId,
              };

              appointment.customer_driver = customer_driver;

              let selectedCustomer = `${customer_driver.firstname} ${customer_driver.surname}`;

              this.setState({ appointment, selectedCustomer, selectCustomerLoading: false });
            }
          })
          .catch(error => {
            console.log("Error retrieving customer: ", error);
            this.setState({
              selectCustomerLoading: false,
              hasError: true,
              errorMsg: error.response.data && error.response.data.errors ? error.response.data.errors[0] : "Error retrieving customer",
            });
          });
      });
    } else {
      const { customer_driver } = data.result;

      if (customer_driver) {
        const selectedCustomer = `${customer_driver.firstname} ${customer_driver.surname}`;
        appointment.customer_driver = customer_driver;
        this.setState({ appointment, selectedCustomer });
      }
    }
  };

  handleSearchCustomersByCar = async () => {
    const { appointment } = this.state;
    const dealer_location_id = this.props.location.id;
    let response = null;
    let visibleResults = [];
    try {
      this.setState({ searchCustomersByCarLoading: true, customerSearchResult: [] }, async () => {
        if (this.props.keyloopEnabled) {
          response = appointment?.car?.dms_nr && (await Service.searchCustomersByCar({ dealer_location_id, vehicle_id: appointment.car.dms_nr }));
          response?.data?.data?.customers &&
            response.data.data.customers.forEach((customer_driver, key) => {
              visibleResults.push({
                key,
                id: customer_driver.customerId,
                title: `${customer_driver.names.givenName} ${customer_driver.names.familyName} - ${customer_driver.relationship.replace("_", "/")}`,
                customer_driver,
              });
            });
        } else if (nextlaneEnabled(this.props.location)) {
          response = appointment?.car?.dms_nr && (await Service.searchNextlaneCustomersByCar({ dealer_location_id, dms_nr: appointment.car.dms_nr }));
          response?.data?.data?.customers &&
            response.data.data.customers.forEach((customer_driver, key) => {
              visibleResults.push({
                key,
                id: customer_driver.customerId,
                title: `${customer_driver.names.givenName} ${customer_driver.names.familyName} - ${customer_driver.relationship.replace("_", "/")}`,
                customer_driver,
              });
            });
        } else {
          response = await CarsService.getCustomersByCar({ reg_nr: appointment.car.reg_nr, dealer_location_id });
          response?.data?.data &&
            response.data.data.forEach(customer_driver => {
              visibleResults.push({
                key: customer_driver.id,
                id: customer_driver.id,
                title: `${customer_driver.title || ""} ${customer_driver.firstname || ""} ${customer_driver.surname || ""} - ${customer_driver.place || ""}`,
                customer_driver,
              });
            });
        }

        this.setState({ searchCustomersByCarLoading: false, customerSearchResult: visibleResults });
      });
    } catch (error) {
      console.log("Error fetching customers", error);
      this.setState({
        searchCustomersByCarLoading: false,
        customerSearchResult: [],
        hasError: true,
        errorMsg:
          error?.response?.data?.errors?.length > 0 ? error.response.data.errors[0] : this.props.t("err_retrieving_customers").message || "Error retrieving customers",
      });
    }
  };

  handleSearchCarsByCustomer = () => {
    if (this.state.appointment && this.state.appointment.customer_driver && this.state.appointment.customer_driver.id) {
      this.setState({ isCarSearchLoading: true, customerCarsSearchResults: [], customerCarValue: "" }, () => {
        Service.searchCarsByCustomer({ customer_id: this.state.appointment.customer_driver.id })
          .then(result => {
            let visibleResults = [];
            if (result.data && result.data.data) {
              result.data.data.cars.forEach((car, key) => {
                visibleResults.push({ key, id: car.id, title: `${car.reg_nr || ""} ${car.make || ""} ${car.model || ""} ${car.vin_nr || ""}`, car });
              });
            }

            this.setState({ isCarSearchLoading: false, customerCarsSearchResults: visibleResults });
          })
          .catch(error => {
            console.log("Error searching customers.", error);
            this.setState(
              {
                isCarSearchLoading: false,
                customerCarsSearchResults: [],
                searchCarsHasError: true,
                errorMsg: this.props.t("cars_not_found").message || "Cars not found.",
              },
              () => {
                setTimeout(() => {
                  this.setState({ searchCarsHasError: false });
                }, 2000);
              }
            );
          });
      });
    }
  };

  handleCustomerCarSelect = (event, data) => {
    let appointment = this.state.appointment;

    const car = data.result.car;
    appointment.car = {
      reg_nr: car.reg_nr,
      apk_date_dms: car.apk_date_dms ? moment(car.apk_date_dms) : car.apk_date_dms,
      make: car.make,
      model: car.model,
      vin_nr: car.vin_nr,
    };

    this.setState({ appointment, customerCarValue: "" });
  };

  handleSave = () => {
    if (!this.validateForm()) {
      this.setState({ hasError: true, errorMsg: "Please check your input. Fill in all the required fields." });
      return;
    }

    let { appointment } = this.state;
    let { car_return_time, time_car_app, due_in_time } = appointment;
    let due_in_date = moment(time_car_app).format("YYYY-MM-DD");

    appointment.car_return_time = moment(car_return_time).format("YYYY-MM-DDTHH:mm:ssZ");
    due_in_time = moment(due_in_time).format("HH:mm");
    appointment.due_in = moment(due_in_date + " " + due_in_time).format("YYYY-MM-DDTHH:mm:ssZ");
    if (!appointment.customer_driver.dms_nr) appointment.customer_driver.dms_nr = `claire_${moment().utc().format("YYYY-MM-DDTHH:mm:ssZ")}`;

    this.props.onSave(appointment);
  };

  handleToggleAddAppointmentIntervnetionModal = () => {
    this.setState(({ isAddInterventionVisible }) => ({ isAddInterventionVisible: !isAddInterventionVisible }));
  };

  render() {
    const { location, keyloopEnabled, t, isLoading, addAppointmentError, user } = this.props;
    const {
      isCarSearchLoading,
      carSearchTerm,
      carSearchResults,
      selectedCustomer,
      customerSearchResult,
      customerSearchTerm,
      isCustomerSearchLoading,
      customerSearchResults,
      appointment,
      searchCarsHasError,
      hasError,
      errorMsg,
      customerCarValue,
      customerCarsSearchResults,
      carSearchChangeLoading,
      searchCustomersByCarLoading,
      selectCustomerLoading,
      isAddInterventionVisible,
    } = this.state;

    let RDW = keyloopEnabled ? null : (
      <Button
        disabled={!appointment.car.reg_nr}
        color="green"
        className="RDWButton"
        onClick={() => {
          this.getRdw(appointment.car.reg_nr);
        }}
      >
        RDW
      </Button>
    );

    return (
      <div className="AddAppointmentForm">
        <Modal.Content image>
          <Form>
            <h2>
              <Icon name="car" />
              {t("car").message}
              <>
                <Search
                  size="mini"
                  noResultsMessage={t("no_results").message || "No results found"}
                  loading={carSearchChangeLoading}
                  minCharacters={4}
                  onSearchChange={this.handleCarSearchChange}
                  onResultSelect={this.handleCarResultSelect}
                  results={carSearchResults}
                  value={carSearchTerm}
                />
                <Button color="green" disabled={!appointment.car.reg_nr} loading={searchCustomersByCarLoading} onClick={this.handleSearchCustomersByCar}>
                  <Icon name="user" />
                  {t("find_customer").message || "Find customer"}
                </Button>
                {customerSearchResult && customerSearchResult.length > 0 && (
                  <Search
                    size="mini"
                    defaultOpen
                    loading={selectCustomerLoading}
                    noResultsMessage={t("no_results").message || "No results found"}
                    minCharacters={0}
                    onResultSelect={this.handleSelectCustomer}
                    results={customerSearchResult}
                    value={selectedCustomer}
                  />
                )}
              </>
            </h2>
            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("reg_nr").message}</label>
                <UserInput
                  label={RDW}
                  labelPosition={keyloopEnabled ? null : "right"}
                  placeholder={t("reg_nr").message}
                  name="reg_nr"
                  value={appointment.car.reg_nr}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => {
                    this.handleChange(e, d, "car");
                  }}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t("car_make").message}</label>
                <UserInput
                  name="make"
                  placeholder={t("car_make").message}
                  value={appointment.car.make}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "car")}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t("car_model").message}</label>
                <UserInput
                  name="model"
                  placeholder={t("car_model").message}
                  value={appointment.car.model}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "car")}
                />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>{t("apk_date").message}</label>
                <DatePicker
                  readOnly
                  placeholderText={t("apk_date").message}
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  selected={appointment.car.apk_date_dms ? moment(appointment.car.apk_date_dms).toDate() : null}
                  onChange={(e, d) => this.handleChange(e, d, "apk_date")}
                  onChangeRaw={e => e.preventDefault()}
                />
              </Form.Field>

              <Form.Field>
                <label>{t("vin_nr").message}</label>
                <UserInput
                  name="vin_nr"
                  placeholder={t("vin_nr").message}
                  value={appointment.car.vin_nr}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "car")}
                />
              </Form.Field>
              <Form.Field></Form.Field>
              {keyloopEnabled && <Form.Field></Form.Field>}
            </Form.Group>

            <h2>
              {!keyloopEnabled && (
                <>
                  <Icon name="user" />
                  {t("customer").message}
                  <Search
                    size="mini"
                    noResultsMessage={t("no_results").message || "No results found"}
                    loading={isCustomerSearchLoading}
                    minCharacters={4}
                    onSearchChange={this.handleCustomerSearchChange}
                    onResultSelect={this.handleCustomerResultSelect}
                    results={customerSearchResults}
                    value={customerSearchTerm}
                  />

                  <Button
                    color="green"
                    className="GetCarsButton"
                    disabled={!appointment.customer_driver.id}
                    loading={isCarSearchLoading}
                    onClick={this.handleSearchCarsByCustomer}
                  >
                    <Icon name="car" />
                    {t("find_car").message || "Find Car"}
                  </Button>
                </>
              )}

              {customerCarsSearchResults && customerCarsSearchResults.length > 0 && (
                <Search
                  size="mini"
                  disabled={!appointment.customer_driver.id}
                  defaultOpen
                  noResultsMessage={t("no_results").message || "No results found"}
                  minCharacters={0}
                  onResultSelect={this.handleCustomerCarSelect}
                  results={
                    customerCarValue && customerCarValue.length > 0
                      ? customerCarsSearchResults.filter(x => x.title.toUpperCase().includes(customerCarValue.toUpperCase()))
                      : customerCarsSearchResults
                  }
                  onSearchChange={(e, { value }) => {
                    this.setState({ customerCarValue: value });
                  }}
                  value={customerCarValue}
                />
              )}
            </h2>

            {searchCarsHasError && (
              <Message
                warning
                visible={searchCarsHasError}
                onDismiss={() => {
                  this.setState({ searchCarsHasError: false, errorMsg: "" });
                }}
                header={errorMsg}
              />
            )}

            <Form.Group widths="equal">
              <Form.Field required={this.props.keyloopEnabled}>
                <label>{t("dms_nr").message}</label>
                <UserInput
                  placeholder={t("dms_nr").message}
                  name="dms_nr"
                  value={appointment.customer_driver.dms_nr}
                  disabled={keyloopEnabled || appointment.customer_driver.id > 0}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field required={!location["first_name_optional"]}>
                <label>{t("first_name").message}</label>
                <UserInput
                  placeholder={t("first_name").message}
                  name="firstname"
                  value={appointment.customer_driver.firstname}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t("last_name").message}</label>
                <UserInput
                  placeholder={t("last_name").message}
                  name="surname"
                  value={appointment.customer_driver.surname}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("street_house_nr").message}</label>
                <UserInput
                  placeholder={t("street_house_nr").message}
                  name="street"
                  value={appointment.customer_driver.street}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("postcode").message || "WO#"}</label>
                <UserInput
                  placeholder={t("postcode").message}
                  name="postcode"
                  value={appointment.customer_driver.postcode}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("Location").message}</label>
                <UserInput
                  placeholder={t("Location").message}
                  name="place"
                  value={appointment.customer_driver.place}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group widths="equal">
              <Form.Field>
                <label>{t("email").message}</label>
                <UserInput
                  placeholder={t("email").message}
                  name="email_business"
                  value={appointment.customer_driver.email_business}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("mobile").message || "WO#"}</label>
                <UserInput
                  placeholder={t("mobile").message}
                  name="tel_mobile_business"
                  value={appointment.customer_driver.tel_mobile_business}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("phone").message}</label>
                <UserInput
                  placeholder={t("phone").message}
                  name="tel_business_nr"
                  value={appointment.customer_driver.tel_business_nr}
                  disabled={keyloopEnabled}
                  onChange={(e, d) => this.handleChange(e, d, "customer_driver")}
                />
              </Form.Field>
            </Form.Group>

            <h2>
              <Icon name="list" />
              {t("appointment").message}
            </h2>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>{t("appointment_date").message}</label>
                <DatePicker
                  placeholderText={t("appointment_date").message}
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  minDate={moment().toDate()}
                  selected={moment(appointment.time_car_app).toDate()}
                  onChange={(e, d) => this.handleChange(e, d, "time_car_app")}
                  onChangeRaw={e => e.preventDefault()}
                />
              </Form.Field>
              <Form.Field>
                <label>{t("ref_nr").message}</label>
                <UserInput placeholder={t("ref_nr").message} name="ref_nr" value={appointment.ref_nr} onChange={this.handleChange} />
              </Form.Field>
              {!keyloopEnabled && (
                <Form.Field required>
                  <label>{t("wo_nr").message || "WO #"}</label>
                  <UserInput placeholder={t("wo_nr").message} name="wo_nr" value={appointment.wo_nr} onChange={this.handleChange} />
                </Form.Field>
              )}
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field required>
                <label>{t("scheduled_in_date").message || "Scheduled-in date"}</label>
                <DatePicker
                  placeholderText={t("scheduled_in_date").message}
                  readOnly
                  dateFormat="dd-MM-yyyy"
                  todayButton={t("today").message || "Today"}
                  showMonthDropdown
                  showYearDropdown
                  selected={moment(appointment.time_car_app).toDate()}
                  onChangeRaw={e => e.preventDefault()}
                />
              </Form.Field>
              <Form.Field required>
                <label>{t("scheduled_in_time").message || "Scheduled-in time"}</label>
                <TimePicker showSecond={false} onChange={this.handleChangeDueInTime} value={appointment.due_in_time} />
              </Form.Field>
            </Form.Group>
            <Form.Group widths="equal">
              {this.props.enable_car_ready && (
                <Form.Field required>
                  <label>{t("scheduled_out").message || "Scheduled out"}</label>
                  <DatePicker
                    placeholderText={t("scheduled_out" || "Scheduled out").message}
                    dateFormat="dd-MM-yyyy h:mm"
                    todayButton={t("today").message || "Today"}
                    showMonthDropdown
                    showYearDropdown
                    showTimeInput
                    minDate={moment().toDate()}
                    selected={appointment.car_return_time ? moment(appointment.car_return_time).toDate() : null}
                    onChange={(e, d) => this.handleChange(e, d, "car_return_time")}
                    onChangeRaw={e => e.preventDefault()}
                  />
                </Form.Field>
              )}

              <Form.Field>
                <label>{t("car_in_shop").message || "Car in shop"}</label>
                <Checkbox toggle onChange={this.handleChangeCarInShopCheckbox} name="car_in_shop" />
              </Form.Field>
            </Form.Group>

            {appointment.interventions?.length > 0 &&
              appointment.interventions.map((i, k) => (
                <div>
                  <Form.Group key={k}>
                    <Form.Field style={{ width: "30%" }}>
                      <label>{t("intervention").message || "Interventie"}</label>
                      <UserInput
                        placeholder={t("intervention").message || "Interventie"}
                        name="title"
                        value={i.title}
                        onChange={(e, d) => this.handleChange(e, d, "appointment_intervention", { index: k })}
                      />
                    </Form.Field>
                    {!this.props.keyloopEnabled && (
                      <Form.Field style={{ width: "30%" }}>
                        <label>{t("remark").message || "Remark"}</label>
                        <UserInput
                          placeholder={t("remark").message || "Remark"}
                          name="description"
                          value={i.description}
                          onChange={(e, d) => this.handleChange(e, d, "appointment_intervention", { index: k })}
                        />
                      </Form.Field>
                    )}
                    <Form.Field>
                      <label>&nbsp;</label>
                      <ThumbToggle status={i.customer_ok} onClick={(e, d) => this.handleChange(e, d, "intervention_toggle", { index: k })} />
                    </Form.Field>

                    <Can I="update" the="appointments">
                      <Form.Field>
                        <label>&nbsp;</label>
                        <Button color="red" icon onClick={() => this.removeIntervention(k)}>
                          <Icon name="trash" />
                        </Button>
                      </Form.Field>
                    </Can>
                  </Form.Group>
                </div>
              ))}

            <Can I="add" the="interventions">
              <Form.Group>
                <Form.Field>
                  <Button icon labelPosition="left" color="green" onClick={this.handleToggleAddAppointmentIntervnetionModal}>
                    <Icon name="plus" />
                    {t("add_intervention").message}
                  </Button>
                </Form.Field>
              </Form.Group>
            </Can>

            {hasError && <Message icon="warning" color="yellow" header={t(errorMsg).message || errorMsg} />}
            {addAppointmentError && <Message icon="warning" color="red" header={addAppointmentError} />}
          </Form>

          {isAddInterventionVisible && (
            <AddAppointmentIntervention
              keyloopEnabled={keyloopEnabled}
              appointment_id={appointment.id}
              dms_id={location.dms_id}
              user={user}
              onClose={this.handleToggleAddAppointmentIntervnetionModal}
              onSubmit={this.addIntervention}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button size="large" color="red" disabled={isLoading} floated="left" onClick={this.props.onHide}>
            {t("close").message}
          </Button>
          <Button size="large" color="green" floated="right" disabled={!this.validateForm() || isLoading} onClick={this.handleSave} loading={isLoading}>
            {t("save").message}
          </Button>
        </Modal.Actions>
      </div>
    );
  }
}

export default withTranslation()(AddAppointmentForm);
